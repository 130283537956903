var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.banner.body && _vm.banner.cleanUrl
    ? _c(
        "a",
        { staticClass: "banner-text", attrs: { href: _vm.banner.cleanUrl } },
        [
          _c("h3", { staticClass: "banner-text__title" }, [
            _vm._v("\n    " + _vm._s(_vm.banner.body) + "\n    "),
            _vm.banner.params.doShowArrow
              ? _c("img", {
                  staticClass: "banner-text__title--arrow",
                  attrs: {
                    src: "/frontend-new/assets/icons/banner-arrow.svg",
                    alt: _vm.banner.title
                  }
                })
              : _vm._e()
          ])
        ]
      )
    : _vm.banner.body && !_vm.banner.cleanUrl
    ? _c("div", { staticClass: "banner-text" }, [
        _c("h3", { staticClass: "banner-text__title" }, [
          _vm._v("\n    " + _vm._s(_vm.banner.body) + "\n    "),
          _vm.banner.params.doShowArrow
            ? _c("img", {
                staticClass: "banner-text__title--arrow",
                attrs: {
                  src: "/frontend-new/assets/icons/banner-arrow.svg",
                  alt: _vm.banner.title
                }
              })
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }